<template>
  <div class="accountset">
    <userheader menucurrent=2 />
    <div class="main-div">
      <div class="account-content wrap">
        <!-- 左侧导航 -->
        <userleft currindex=10 ref="userleft" />

        <div class="main-box">
          <div class="account-main">
            <div class="item-block">
              <h3 class="title">实名认证信息<em></em></h3>
              <div class="info-cnt">
                <div class="content-rb " v-if="userinfo!=null">
                 
                  <el-row :gutter="20" align="middle" v-if="userinfo.iDcardAuthStatus==1">
                    <el-col :span="16"  :offset="5">
                      <el-card shadow="always" >
                        <div class="info-top" v-if="false">
                          <el-avatar shape="circle" :size="80" :src="userinfo.userAvatar"></el-avatar>
                          <h2 style="margin: 0; font-size: 1.2rem; line-height: 3rem;">{{userNickName }}</h2>
                        </div>
                        <div class="info-bottom">
                          <div>
                            <table class="demo-border">
                              <tbody>
                                <tr v-if="userinfo.iDcardName!=null">
                                  <td class="text">姓名</td>
                                  <td class="text">{{userinfo.iDcardName}}</td>
                                </tr>
                                <tr v-if="userinfo.iDcardSex!=null&&userinfo.iDcardSex!=''">
                                  <td class="text">性别</td>
                                  <td class="text">{{userinfo.iDcardSex}}</td>
                                </tr>
                                  <tr v-if="userinfo.iDcardBirthdate!=null&&userinfo.iDcardBirthdate!=''">
                                  <td class="text">生日</td>
                                  <td class="text">{{userinfo.iDcardBirthdate}}</td>
                                </tr>
                                <tr v-if="userinfo.iDcardNation!=null&&userinfo.iDcardNation!=''">
                                  <td class="text">民族</td>
                                  <td class="text">{{userinfo.iDcardNation}}</td>
                                </tr> 
                                 <tr v-if="userinfo.iDcardAddress!=null&&userinfo.iDcardAddress!=''">
                                  <td class="text">住址</td>
                                  <td class="text">{{userinfo.iDcardAddress}}</td>
                                </tr>
                               
                                <tr v-if="userinfo.iDcardNO!=null&&userinfo.iDcardNO!=''">
                                  <td class="text">公民身份号码</td>
                                  <td class="text">{{userinfo.iDcardNO}}</td>
                                </tr>
                                 <tr v-if="userinfo.iDcardIssuingAuthority!=null&&userinfo.iDcardIssuingAuthority!=''">
                                  <td class="text">签发机关</td>
                                  <td class="text">{{userinfo.iDcardIssuingAuthority}}</td>
                                </tr>
                                   <tr >
                                  <td class="text">有效期限</td>
                                  <td class="text">{{userinfo.iDcardValidityperiodStart}}-{{userinfo.iDcardValidityperiodEnd}}</td>
                                </tr>
                                <tr v-if="false">
                                  <td class="text">职称</td>
                                  <td class="text pos">
                                    <el-tag type="success" v-for="item in titleList" v-bind:key="item">{{item}}</el-tag>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </el-card>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20" align="middle" v-else>
                    <el-col :span="16"  :offset="5">
                      <el-card shadow="always" v-if="userinfo!=null">
                        <div class="info-top" v-if="false">
                          <el-avatar shape="circle" :size="80" :src="userinfo.userAvatar"></el-avatar>
                          <h2 style="margin: 0; font-size: 1.2rem; line-height: 3rem;">{{userNickName }}</h2>
                        </div>
                        <div class="info-bottom">
                          <div>
                            <table class="demo-border">
                              <tbody>
                                <tr>
                                  <td class="text" style="text-align:center;border-bottom:none">您尚未进行实名认证，请至<span style="color:rgb(38 142 255)" >牛豆豆APP</span>进行实名认证</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </el-card>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <userfooter />
  </div>
</template>

<script>
import userheader from '@/components/userheader.vue';
import userfooter from '@/components/userfooter.vue';
import Cookies from 'js-cookie';
import {getCookieByName} from '@/utils/helper';
var token =getCookieByName('access_token');
import {
  usergetcurruserinfo,
  getuserinfobydesuid,
  getuserpostandtitle,
} from '@/api/user';
import userleft from '@/components/userleft.vue';
export default {
  name: 'App',
  components: {
    userheader: userheader,
    userfooter: userfooter,
    userleft: userleft,
  },
  data() {
    const self = this;
    return {
      bigqrcodeurl: '',
      dialogImageUrl: '',
      dialogVisible: false,
      firstShow: true,
      show: false,
      userinfo: null,
      postandinfo: null,
      titleList: [],
      companyPostList: null,
      userNickName: '',
      activeName: '1',
    };
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
    },
    onuserleft() {
      this.$refs.userleft.handleqrcode();
    },
    ongetuserpostandtitle() {
      let params = { desUId: getCookieByName('user_id') };
      getuserpostandtitle(params).then((res) => {
        if (res.data.result !== null) {
          this.companyPostList = res.data.result.companyPostList;
          this.titleList = res.data.result.titleList;
        }
      });
    },
  },
  created() {
    var that = this;
    usergetcurruserinfo().then((res) => {
      if (res.data.code == 1) {
        this.userinfo = res.data.result;
        if (that.userinfo.userNickName) {
          that.userNickName = this.userinfo.userNickName;
        } else {
          that.userNickName = this.userinfo.userMobile;
        }
      }
    });
    this.ongetuserpostandtitle();
  },
  mounted() {},
};
</script>
<style  scoped >
/**公共css开始**/
body {
  background-color: #eaecef;
  /* height: 100%; */
}
.wrap {
  width: 1190px;
  margin-left: auto;
  margin-right: auto;
}
.accountset {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  position: relative;
}
.accountset .main-div {
  margin-top: 40px;
  padding-bottom: 210px;
}
.accountset .account-content {
  padding: 20px 0 0;
  overflow: hidden;
  position: relative;
}

.acount-card {
  background-position: 0 -172px;
  background-image: url('../../../assets/img/account-ico.png');
}
.accountset .main-box {
  float: right;
  width: 940px;
}
.accountset .item-block {
  padding: 20px;
  min-height: 720px;
  background-color: #fff;
}
.accountset .item-block .title {
  font-size: 28px;
  color: #404040;
  line-height: 46px;
  border-bottom: 1px solid #e0e0e0;
  font-weight: 400;
}
.accountset .item-block .title em {
  font-size: 16px;
  color: #404040;
}
.accountset .info-cnt {
  padding: 30px 0;
}

.accountset .info-cnt >>> .el-form-item__label {
  color: #a0a0a0;
  min-width: 84px;
  font-size: 16px;
}

.accountset .info-cnt >>> .el-form-item__content button {
  background-image: linear-gradient(196deg, #5aa5ff, #0d7bff);
  color: #fff;
  border: none;
}
.accountset >>> form.elform {
  width: 460px;
}
.main-nav__qrcode__img {
  position: relative;
  top: 2px;
  margin-left: 5px;
}
/**公共css结束**/
/* .accountset >>> form.elform .formaddress .el-input {
  width: 220px;
  margin-right: 10px;
} */

.content-rb table {
  border-collapse: collapse;
  width: 100%;
  font-size: 14px;
  margin-bottom: 0px;
  line-height: 1.5em;
}

.content-rb table td {
  color: #606266;
}
.content-rb table td,
.content-rb table th {
  border-bottom: 1px solid #e8e8e8;
  padding: 15px;
  max-width: 250px;
}
.content-rb >>> .el-avatar > img {
  width: 100%;
}
.content-rb .info-top {
  text-align: center;
  padding: 20px 0;
}
.content-rb >>> .el-card {
  background-color: #fbfbfb;
}
.content-rb .tip {
  padding: 8px 16px;
  background-color: #ecf8ff;
  border-radius: 4px;
  border-left: 5px solid #50bfff;
  margin: 5px 0 10px;
}
.content-rb >>> .el-collapse-item__content {
  padding-bottom: 0px;
}
.content-rb >>> .el-collapse-item__header {
  font-weight: 600;
  font-size: 17px;
  height: 60px;
  line-height: 60px;
  color: #484848;
  padding-left: 5px;
}
.content-rb >>> .el-tag {
  margin: 0 5px;
  height: 24px;
  line-height: 24px;
  padding: 0 4px;
}
.content-rb table td.pos {
  padding-left: 7px;
}
.content-rb .prefix {
    display: inline-block;
    /* margin-top: 40px; */
    /* margin-left: -20px; */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    line-height: 20px;
    color: #ffffff;
    background: #1989fa;
    text-align: center;
    font-size: 14px;
    top: -1px;
    left: -3px;
    position: relative;
}
</style>
